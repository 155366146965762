
























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({ name: 'IntegrationManagementSectionEnabled' })
export default class IntegrationManagementSectionEnabled extends Vue {
  @Prop({
    type: Boolean,
    required: true,
  }) readonly value!: boolean

  @Prop({
    type: Boolean,
    default: false,
  }) readonly loading!: false

  @Prop({
    type: String,
    default: '',
  }) readonly error!: string
}
