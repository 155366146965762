import { render, staticRenderFns } from "./SectionEnabled.vue?vue&type=template&id=3b234d2e&scoped=true&"
import script from "./SectionEnabled.vue?vue&type=script&lang=ts&"
export * from "./SectionEnabled.vue?vue&type=script&lang=ts&"
import style0 from "./SectionEnabled.vue?vue&type=style&index=0&id=3b234d2e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b234d2e",
  null
  
)

export default component.exports
import {QToggle,Ripple} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QToggle})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {Ripple})
